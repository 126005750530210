.quote-table {
  width: 100%;
  border-collapse: collapse;
}
.quote-table th,
.quote-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: left;
}

.quote-table th {
  font-weight: normal;
}
.printable-content {
  background-color: white;
}
@media print{
    #print{
        display: none;
    }
}