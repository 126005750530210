.container_file{
    min-height: 500px;
}

.container_file #react-doc-viewer{
    min-height: 500px;
}
.error_file{
    text-align: center;
    letter-spacing: 3px;
    margin-top: 50px;
    color: #484848;
}