@import "../../containers/color.css";
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: sans-serif;
}

header {
    height: 58 px;
    box-shadow: 0 1 px 3 px 0 rgba(0, 0, 0, 0.07),
    0 1 px 2 px 0 rgba(0, 0, 0, 0.05);
    color: #212529;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  height: 58px;
}
.menus {
  display: flex;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}
.menu-items a:hover {
  color: var(--main-bg-color);
}
.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}
.arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
}

.dropdown {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
                0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    display: none;
}

.dropdown.show {
    display: block;
}

.dropdown.dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
}
