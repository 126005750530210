@import "../color.css";

.padding-top {
  font-weight: bold;
  font-size: 14x;
  margin-left: 15px;
  color: var(--white-color);
}

.headernm {
  font-weight: bold;
  font-size: 10x;
  margin-left: 37px;
}
.form-control {
  font-size: 12px;
}
.btn-demo {
  border-radius: 150;
  font-size: 12px;
  background-color: var(--main-bg-color);
  color: var(--white-color);
  width: 95%;
  text-align: left;
}
.btn-demo:hover {
  color: var(--white-color);
}
.widthText {
  width: 100%;
  float: inherit !important;
}
.small {
  font-size: 12px;
}
.dot {
  height: 8px;
  width: 8px;
  background-color: var(--white-color);
  border-radius: 50%;
  display: inline-block;
}
.Hipaas_logo {
    width: 260px;
    height: 100px;
    position: absolute;
    left: 55%;
    top: 40%;
    margin-left: -150px;
}
.para {
  position: sticky;
  left: 5%;
  top: 85%;
  text-align: center;
  color: var(--white-color);
}
.contact {
  position: sticky;
  left: 33%;
  top: 95%;
  text-align: center;
  color: var(--white-color) !important;
  font-weight: 700;
  text-decoration: underline;
}
.header_login {
  padding-left: 0px;
}
.vartical-center {
  position: absolute;
  left: 73%;
  top: 25%;
}
.gradient-color {
  background: var(--gradient-color);
}
.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
  font-weight: 500;
}
.ag-theme-balham .ag-row-selected {
  background-color: #ebf7ff;
}
.ag-filter-condition {
  display: none !important;
}
.ag-filter-select {
  display: none;
}
.ag-filter-body-wrapper {
  height: 27px;
  overflow: hidden;
}
.ag-loading-icon {
  color: white !important;
}
.ag-loading-text {
  color: white !important;
}
.ag-theme-balham
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
    .ag-cell-range-single-cell
  ) {
  border-right: none;
}
.ag-theme-balham .ag-header {
  background-color: var(--main-bg-color) !important;
}
.ag-header-cell-text {
  color: var(--white-color);
}
.ag-body-horizontal-scroll {
  margin-top: 12px;
}
.heading_text_menue:hover {
  color: var(--main-bg-color);
}
.head-bold {
  font-weight: bold;
}
.ag-icon-menu {
  opacity: 1 !important;
}
.model-lg {
  width: 1000px !important;
}
.model-xl {
  width: 1100px !important;
}
a {
  color: var(--main-bg-color);
}
.modal-header {
  border-bottom: none;
}
.close {
  z-index: 30;
}
.lift-up-model-body {
  margin-top: -50px;
}
.quantity_Name {
  color: black;
  font-weight: 600;
}
body {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
} /* linear-gradient(29deg, rgba(105,237,241,1) 0%, rgba(89,89,227,1) 0%, rgba(34,201,252,1) 100%) */

.apexcharts-legend {
  display: none !important;
}
.bg-image {
  width: 30%;
  background-color: transparent !important;
  background-size: cover;
  mix-blend-mode: multiply;
}
.image-container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  background-color: transparent !important;
}
.modal-header .close{
  padding: 0rem 1rem;
  margin: -1rem -1rem -1rem auto;
}   

.react-datepicker{
  min-width: 330px !important;
}
.hide-component{
  display: none;
}