@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
@import "../../containers/color.css";

body {
    /* font-family: 'Poppins', sans-serif; */
    /* background: var(--white-background); */
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--filterColor);
    border-radius: 10px;
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar.active {
    margin-left: -250px;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none !important;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: var(--white-color);
}

#sidebar ul p {
    color: var(--black-color);
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 12px !important;
    display: block;
    /* font-family: "Amazon Bookerly"; */
}

#sidebar ul li a:hover {
    color: var(--main-bg-color);
    background: var(--white-color);
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: var(--black-color);
    /* background: var(--white-color); */
}

ul ul a {
    font-size: 12px !important;
    padding-left: 20px !important;
    background: var(--white-color);
}

#sidebar {
    left: 0;
    padding-bottom: 70px;
    height: 90%;
    background: var(--white-color);
    color: var(--black-color);
    overflow: auto;
    transition: all 0.3s;
    position: fixed;
    min-width: 205px;
    max-width: 250px;
}

.padding {
    padding-top: 8px;
    padding-bottom: 2px;
}

.underline {
    height: 2px;
    background-color: var(--main-bg-color);
    margin-left: 12px !important;
    margin-right: 12px !important;
}

.p {
    margin: 0px;
}

#tabs {
    padding-top: 12px;
    background-color: var(--white-color);
}

.nopadding {
    padding: 0px;
}

.smaller-font {
    font-size: 14px;
    text-align: center;
    color: var(--black-color)
}

.background {
    background-color: var(--background-color);
}

.white-background {
    background-color: var(--white-color);
}

td {
    font-size: 12px;
}

.sidebar-width {
    width: 104px;
    z-index: 0;
}

.menu_option {
    word-break: break-all;
}

.headerText {
    /* color: var(--main-bg-color); */
    color: #000000;
    font-weight: 700;
    margin-top: 10px;
    font-size: 18px;
}

.button-table {
    padding-top: 7px !important;
    padding-bottom: 0px !important;
}

.fixed-style {
    position: fixed;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

/* #sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
} */

@media (max-width: 768px) {
    #sidebar {
        z-index: 10;
    }

    #sidebar.active {
        margin-left: 0;
    }
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

.Sidebar_drawer {
    font-size: 20px;
    cursor: pointer;
    color: white;
    margin-left: 90px;
}

#top-bar a[aria-expanded="true"] {
    color: var(--white) !important;
}

.btnDesign {
    background-color: var(--main-bg-color);
    color: var(--white-color);
    font-size: 10px;
    line-height: 1.5;
    border-radius: .2rem;
    border: 1px solid transparent;
    padding: .275rem .75rem;
}

.hl7_image_style {
    width: 100%;
    /* height: 100%; */
    left: 50%;
    top: 50%;
}

.white {
    color: white
}

.tile-container {
    padding-top: 12px;
    padding-bottom: 12px;
    box-shadow: 1px 2px 4px rgb(134 134 134 / 50%);
    border-radius: 8px;
}
ul#Sb40 {
    width: 190px;
}