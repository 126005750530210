 @import "../color.css"; 

 .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    /* Position the tooltip text - see examples below! */
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
.light_blue1 {
  background-color: var(--main-bg-color);
  color: white;
  margin-top: 0px;
  margin-left: 40px;
  padding: 0.25rem 0.4rem;
  font-size: 0.775rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  font-family: "Amazon Bookerly";
}
.Opertion_btn:hover {
  color: white;
}
.Opertion_btn {
  background-color: var(--main-bg-color);
  color: white;
  margin-top: 0px;
  margin-left: 20px;
  /* padding: 0.25rem 0.4rem; */
  font-size: 12px !important;
  line-height: 1.5;
  border-radius: 0.2rem;
  font-family: "Amazon Bookerly";
}

.modal-dialog-error {
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  position: relative;
}
/* .error-dialog {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  max-height: 700px;
  margin-top: 100px;
} */
.custom-header{
    color:white;
}
.error-dialog_2 {
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    max-height: 900px;
  }
  .error-dialog_3 {
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    max-height: 900px;
    margin-top: 100px;
    margin-left: 20%;
  }
.clickable {
  cursor: pointer;
}
.error-dialog{
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    max-height: 700px;
}



.list-dashboard{
    font-size: 12px;
    padding-left: 2px;
}
.header{
    margin-top: 10px;
}
.title_graph{
    color: var(--main-bg-color);
    font-size: 13px;
}
.collapsible {
    background-color: var(--lightgray);
    color: var(--black-color);
    cursor: pointer;
    padding: 5px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    margin-top: 10px;
  }

.content {
    border-color: var(--main-bg-color);
    border-style: solid;
    border-width: thin;
}
.header2{
    margin-left: -28px;
}

.datepicker{
    border: 1px solid var(--white-color);
    border-radius: 4px;
    height: 32px;
    width : 100%
}
.react-datepicker-wrapper{
    width : 100%
}
.button{
    margin-top: 18px;
    border: 1px solid var(--main-bg-color);
    color: var(--white-color);
    background: var(--main-bg-color);
    font-size: 12px;
    padding: 6px;
    text-align: center;
    border-radius: 4px;
    padding-left: 24px;
    padding-right: 24px;
}
.margin-less-button{
    border: 1px solid var(--main-bg-color);
    color: var(--white-color);
    background: var(--main-bg-color);
    font-size: 12px;
    padding: 6px;
    margin-top : 16px;
    margin-left : 16px;
    text-align: center;
    border-radius: 4px;
    padding-left: 24px;
    padding-right: 24px;
}
.form-style{
    font-size: 12px;
    padding-top: 16px;
    padding-left: 2px;
}
.table-head-text-view{
    color: var(--white-color);
    font-size: 10px;
}
.table-head-text-item{
    font-size: 10px;
}
.chart-container{
    border-radius: 8px;
    margin: 12px;
    box-shadow: 1px 2px 4px rgba(134, 134, 134, 0.5);
    width : 46%;
    padding-left : 24px;
    padding-right : 24px;
}
.table td{
    padding: 5px;
}
.chart-container-full{
    border-radius: 8px;
    margin: 4px;
    box-shadow: 1px 2px 4px rgba(134, 134, 134, 0.5);
    padding-left : 24px;
    padding-right : 24px;
}
.chart-container1{
    border-radius: 8px;
    margin: 12px;
    box-shadow: 1px 2px 4px rgba(134, 134, 134, 0.5);
    width : 90%;
    padding-left : 24px;
    padding-right : 24px;
}
.volume-summary{
    padding : 16px;
    box-shadow: 1px 2px 4px rgba(134, 134, 134, 0.5);
    border-radius: 8px;
    margin: 12px;
}
.chart-div{
    /* justify-content: center; */
}
.background-color{
    background-color: var(--white-color)
}
.summary-container{
    box-shadow: 1px 2px 4px rgba(134, 134, 134, 0.5);
    background-color: var(--light-grey);
    margin-right : 16px;
    margin-top : 2px;
    margin-bottom : 16px;
    border-radius: 8px;
}
.summary-container1{
    box-shadow: 1px 2px 4px rgba(110, 134, 134, 0.5);
    background-color: var(--light-grey);
    margin-right : 16px;
    margin-bottom : 10px;
    border-radius: 8px;
    padding-right: 5px;
    padding-left: 5px;
}
.summary-header{
    font-size: 10px;
    color: var(--grey-color);
    padding-top: 12px;
    padding-bottom: 2px;
}
.summary-header1{
    font-size: 10px;
    color: var(--grey-color);
    padding-top: 11px;
    padding-bottom: 0px;
}
.summary-title{
    font-size: 20px !important;
    color: var(--grayBlack);
    /* padding-left: 8px; */
    padding-bottom: 12px;
}
.summary-title1{
    font-size: 20px !important;
    color: var(--grayBlack);
    padding-left: 8px;
    padding-bottom: 5px;
}
/* Add CSS styles as per your requirement */
.copy-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #aaa;
  }
  
.container_file{
    min-height: 500px;
}

.container_file #react-doc-viewer{
    min-height: 500px;
}
.error_file{
    text-align: center;
    letter-spacing: 3px;
    margin-top: 50px;
    color: #484848;
}
::-webkit-scrollbar:vertical {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track:vertical {
    background: #ffffff;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb:vertical {
    background: #b5b4b4;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:vertical:hover {
    background: #b5b4b4;
  }
