@import "../../containers/color.css";

.header_container {
  /* background: linear-gradient(29deg, rgba(105,237,241,1) 0%, rgba(89,89,227,1) 0%, rgba(34,201,252,1) 100%); */
  background-color: var(--main-bg-color);
  padding: 8px;
  position: fixed;
  width: 100%;
  z-index: 10;
  margin-left: -17px;
  padding-right: 20px;
}
.header_text {
  color: var(--white-color) !important;
  margin: -5px;
  font-size: 26px;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 5px;
}
.header_text{
    color: var(--white-color) !important;
    margin:-5px;
    font-size: 26px;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 5px
    
}
.Sidebar_drawer
{
    font-size:20px;cursor:pointer;
    color: white;
    /* padding-left: 90px; */
}
.heading_text{
    color: var(--main-bg-color) !important;
    margin:-5px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 5px
}
.heading_text {
  color: var(--main-bg-color) !important;
  margin: -5px;
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 5px;
}
.dropdown_logout {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--white-color);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px var(--main-bg-color);
  z-index: 1;
  right: 0;
  cursor: pointer;
}
.dropdown-content a {
  color: var(--black-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: var(--main-bg-color);
  color: var(--white-color) !important;
}

.dropdown_logout:hover .dropdown-content {
  display: block;
}
.autocomplete-dropdown {
  position: absolute;
  display: inline-block;
  z-index: 1;
  margin-top: 34px;
}
.dropdown-inner-item {
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #f4f4f4;
  min-width: 160px;
  font-size: 10px;
  box-shadow: 0px 8px 16px 0px var(--lightgray);
  z-index: 1;
  right: 0;
  cursor: pointer;
}

.dropdown-inner-item a {
  color: var(--black-color);
  padding: 4px 4px;
  text-decoration: none;
  display: block;
}
.dropdown-inner-item a:hover {
  background-color: var(--main-bg-color);
  color: var(--white-color) !important;
}
.card {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  /* padding: 5px; */
  margin: 1px;
}
.ag-icon-menu {
  color: white;
}
.ag-header-icon {
}
.card-header {
  padding: 0.2rem 1.25rem !important;
}
.btn {
  font-size: 14px !important;
}
body {
  font-size: 12px !important;
}
.nav-tabs {
  color: var(--main-bg-color) !important;
}
.glassMorphism {
  display: flex;
  align-items: center;
  backdrop-filter: blur(16px) saturate(10%);
  -webkit-backdrop-filter: blur(16px) saturate(10%);
  background-color: white;
  border: 1px solid rgba(34, 65, 105, 0.3);
  box-shadow: rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -1px 1px;
}
