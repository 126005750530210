.form-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.form-container .header_1 {
  font-size: 22px;
  color: #002596;
  padding-bottom: 10px;
}

.form-container .form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.form-container .form-group .filters{
    display: flex;
    align-items: center;
    gap: 50px;
}
.form-container .form-input {
  font-size: 12px;
  display: flex;
  gap: 1px;
  align-items: center;
}

.form-container .form-input label,
.form-container .form-input select {
  margin-bottom: 0;
  padding: 5px 0px;
}
.form-container .form-input label {
  min-width: 90px;
}
.form-container .form-input select {
  padding: 8px 0px;

}
.form-container .form-action {
  padding-top: 10px;
}

.form-container .form-action table,
.form-container .form-action table td,
.form-container .form-action table th {
  border: 1px solid #ddd;
  text-align: left;
  font-size: 14px;
}

.form-container .form-action table {
  border-collapse: collapse;
  width: 50%;
}

.form-container .form-action th,
.form-container .form-action td {
  padding: 15px;
}

.form-container .form-action input{
  cursor: pointer;
}
.form-container .formInputs{
  margin-top: 20px;
  display: grid;
  grid-template-columns: 400px 400px;
  gap: 30px;

}

.form-container .formInputs label{
  min-width: 100px;
}
.form-container .formInputs input{
  margin-bottom: 0;
  padding: 5px 0px;
  padding-left: 5px;
}
.form-container .formInputs .form-input{
justify-content: space-between;
}

.form-container .formInputs input,
.form-container .formInputs select{
 min-width: 250px;
}

.form-container .formInputs  ul {
list-style: none;
width: 100%;
}

.form-container .formInputs ul li{
  cursor: pointer;
}

.form-container .rect_1{
  padding: 10px;
  width: 250px;
  height: 300px;
  display: flex;
  border: 1px solid black;

}

.form-container .transfer_box{
  grid-column-start: 1;
  grid-column-end: -1;
}

.form-container .boxes{
  display: flex;
  gap: 10px;
  /* margin-left: 50px; */
}
.form-container .form-input.transfer_box{
  justify-content: unset;
  align-items: unset;
  grid-column-end: -1;

}

.form-container .icons{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-container .icons span{
  cursor: pointer;
}

.form-container .userManageBtn{
  padding: 5px 10px;
  background-color: #002596;
  color: white;
}
