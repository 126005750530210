@import "../../color.css";
.invoice-container-d-d {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.invoice-header {
  text-align: center;
  margin-bottom: 20px;
  color: var(--main-bg-color);
}
p {
  color: black !important;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.bold {
  font-weight: 600;
  width: 50%;
}
.bold_new {
  font-weight: 600;
  width: 10%;
}
td {
  border: 1px solid #ddd;
  height: fit-content;
}
table {
  border: 1px solid #ddd;
}
tr {
  border: 1px solid #ddd;
}
.d-block {
  display: block;
  border: none;
}
th {
  border: 1px solid #ddd;
  padding: 5px;
  word-wrap: none !important;
}
.groupcolumn {
  padding: 0px;
  margin-bottom: -5px;
  display: flex;
  justify-content: space-between;
}
.invoice-container-form-22 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  font-size: 14px;
}
.invoice-container-form-22 p {
  color: black;
  font-size: 14px;
  font-weight: 400;
}
.left-div {
  width: 50%;
  word-wrap: break-word;
}
.right-div {
  margin-top: 10px !important;
  width: 50%;
  word-wrap: break-word;
  display: flex;
  margin-left: 10px;
  height: fit-content;
  border-bottom: 2px solid #837c7c;
  border-bottom-style: dotted;
  text-transform: uppercase;
}
.border-div {
  display: flex;
  justify-content: center;
  width: 453px;
  border-bottom: 2px solid grey;
  border-bottom-style: dotted;
}
.invoice-container-form-22 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  font-size: 14px;
}
.invoice-container-form-22 p {
  color: black;
  font-size: 14px;
  font-weight: 400;
}
.left-div {
  width: 50%;
  word-wrap: break-word;
}
.right-div {
  width: 50%;
  word-wrap: break-word;
  display: flex;
  margin-left: 10px;
  height: fit-content;
  border-bottom: 2px solid #837c7c;
}

.invoice-container-gatepass {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}
.invoice-header {
  text-align: center;
  margin-bottom: 20px;
  color: var(--main-bg-color);
}
.bold-gatepaas {
  font-weight: 600;
}
.card-gatepass {
  background-color: rgb(242, 241, 240);
  border-radius: 0;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
tr {
  width: 100%;
}
table {
  width: 100%;
}
.invoice-container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.invoice-header {
  text-align: center;
  margin-bottom: 20px;
  color: var(--main-bg-color);
}
.quotation_name {
  max-width: 100px !important;
  display: flex;
  justify-content: space-between;
}
.itemvalue {
  word-wrap: break-word;
  width: 200px;
}
p {
  color: black;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.bold {
  font-weight: 600;
  width: 50%;
}
.bold_new {
  font-weight: 600;
  width: 10%;
}
td {
  border: 1px solid #ddd;
  padding: 5px !important;
}
.u-border {
  border-bottom: 1px solid #ccc;
  margin: 0 0.1px;
  padding: 0;
}
.remove-border {
  margin: 0 0.1px;
  padding: 0;
}
.itemvalue2 {
  text-align: end;
}
.left-border {
  border-left: none;
  border-top: none;
}
.right-border {
  border-right: none;
  border-top: none;
}
.page-header,
.page-header-space {
  height: 200px;
}

.page-footer,
.page-footer-space {
  height: 100px;
}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.page-header {
  position: fixed;
  top: 0mm;
  width: 100%;
}
@media print {
  .thead {
    display: table-header-group;
  }
  .tfoot {
    display: table-footer-group;
  }
  body{
    margin-top: -10vh;
  }
  #printArea {
    margin: 0;
    margin-left: -50px;
    max-width: 900px;
  }
}
