@import "../../color.css";

/* ----- v CAN BE DELETED v ----- */
body {

font-size: 12px;
}
.form-control{
  font-size: 12px !important;
}
.btn {
  font-size: 12px !important;
}
.btn-demo {
margin: 15px;
padding: 10px 15px;
border-radius: 150;
font-size: 12px;
  background-color: var(--main-bg-color);

}
.table-text{
  background-color: var(--main-bg-color);
  color: var(--white-color)
}
.btn-demo:hover {
  color: var(--white-color);
}
.btn-design{
  margin-left: 15px;
  padding: 10px 15px;
  border-radius: 150;
  font-size: 12px;
  background-color: var(--main-bg-color);
  color: var(--white-color);
}
.widthHandling{
  padding-right: 50px !important;
}










