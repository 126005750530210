@media print {
    body {
      visibility: hidden;
    }
    #printArea {
    visibility: visible;
    position: relative;
    top: -50px;
    left: -60px;
    }
  }